/******* Bootstrap Theming ********/

$primary: #b76e79;
$secondary: #FFFFFF;
$light: #DAF5FB;
$dark: #28464B;

$font-family-sans-serif: 'Montserrat', sans-serif;

$headings-font-weight: 400;

$body-bg: $light;

$body-color: #818491;

$headings-color: $dark;

$headings-font-weight: 600;

$enable-responsive-font-sizes: true;

$enable-rounded: false;

$border-width: 2px;

@import "~bootstrap/scss/bootstrap";


select {
    height: 52px !important;
}

.btn {
  position: relative;

}

.button:active {
  background: #007a63;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
/********** Template CSS ************/
.font-secondary {
    font-family: 'Great Vibes', cursive;
}

.btn-square {
    width: 40px;
    height: 40px;
}

.btn-sm-square {
    width: 32px;
    height: 32px;
}

.btn-lg-square {
    width: 50px;
    height: 50px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
    padding-left: 0;
    padding-right: 0;
    text-align: center;
}

.scroll-to-bottom {
    position: fixed;
    width: 20px;
    left: calc(50% - 10px);
    bottom: 30px;
    z-index: 11;
    -webkit-animation: action 1s infinite alternate;
    animation: action 1s infinite alternate;
}

.back-to-top {
    position: fixed;
    display: none;
    text-align: center;
    right: 30px;
    bottom: 30px;
    z-index: 11;
    -webkit-animation: action 1s infinite alternate;
    animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-15px);
    }
}

@keyframes action {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-15px);
    }
}

.submission {
    &.success {
        .submission-successful {
            display:block;
        }
        .submission-failure {
            display:none;
        }
        &.attending {
            .attendance-out {
                display:none
            }
            .attendance-in {
                display:block
            }
        }
        &.not-attending {
            .attendance-in {
                display:none
            }
            .attendance-out {
                display:block
            }
        }
    }
    &.failure {
        .submission-successful {
            display:none;
        }
        .submission-failure {
            display:block;
        }
    }
}

.navbar-dark {
    display: none;
}

.navbar-dark .navbar-nav .nav-link {
    padding: 25px 10px;
    color: $light;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;
    outline: none;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
    color: $primary;
}

@media (max-width: 991.98px) {
    .navbar-dark .navbar-nav .nav-link {
        padding: 10px 0;
    }
}

.carousel-caption {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .5);
    z-index: 1;
}

.section-title {
    margin-bottom: 30px;
}

.section-title::before {
    position: absolute;
    content: "";
    width: 60px;
    height: 2px;
    bottom: 11px;
    left: calc(50% - 80px);
    background: $primary;
}

.section-title::after {
    position: absolute;
    content: "";
    width: 60px;
    height: 2px;
    bottom: 11px;
    right: calc(50% - 80px);
    background: $primary;
}

.btn-play {
    position: relative;
    margin-top: 50px;
    display: block;
    box-sizing: content-box;
    width: 16px;
    height: 26px;
    border-radius: 100%;
    border: none;
    outline: none !important;
    padding: 18px 20px 20px 28px;
    background: $light;
}

.btn-play:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: $light;
    border-radius: 100%;
    animation: pulse-border 1500ms ease-out infinite;
}

.btn-play:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: $light;
    border-radius: 100%;
    transition: all 200ms;
}

.btn-play span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    left: -1px;
    border-left: 16px solid $primary;
    border-top: 11px solid transparent;
    border-bottom: 11px solid transparent;
}

@keyframes pulse-border {
    0% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
        opacity: 0;
    }
}

#videoModal .modal-dialog {
    position: relative;
    max-width: 800px;
    margin: 60px auto 0 auto;
}

#videoModal .modal-body {
    position: relative;
    padding: 0px;
}

#videoModal .close {
    position: absolute;
    width: 30px;
    height: 30px;
    right: 0px;
    top: -30px;
    z-index: 999;
    font-size: 30px;
    font-weight: normal;
    color: #ffffff;
    background: #000000;
    opacity: 1;
}

.timeline .row {
    position: relative;
    margin-bottom: 30px;
}

@media (min-width: 768px) {
    .timeline::after {
        position: absolute;
        content: "";
        width: 2px;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: -1px;
        background: $primary;
    }
    
    .timeline .row::before {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        top: calc(50% - 15px);
        left: calc(50% - 15px);
        background: $light;
        border: 2px solid $primary;
        transform: rotate(45deg);
        z-index: 1;
    }
    
    .timeline .row::after {
        content: "\f004";
        font-family: "Font Awesome 5 Free";
        font-weight: 400;
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 8px);
        color: $primary;
        z-index: 2;
    }
}

.gallery-item {
    position: relative;
    overflow: hidden;
}

.gallery-item img {
    transition: .5s;
}

.gallery-item:hover img {
    transform: scale(1.2);
}

.gallery-item a {
    position: absolute;
    width: 60px;
    height: 60px;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $light;
    text-decoration: none;
    transition: .5s;
    opacity: 0;
}

.gallery-item:hover a {
    opacity: 1;
}

.gallery-carousel .owl-nav {
    position: absolute;
    width: 135px;
    height: 60px;
    top: -90px;
    left: calc(50% - 67px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
}

.gallery-carousel .owl-nav .owl-prev,
.gallery-carousel .owl-nav .owl-next {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary;
    background: $light;
    font-size: 30px;
}

.img-event-1 {
	width:300px;
	height:200px;
}

@media (max-width: 767.98px) {
    #event .border-right {
        border-right: none !important;
    }
}
